import validate from 'validate.js';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css"

document.addEventListener('DOMContentLoaded',function() {

    const init = function(el, constraints) {
        const form = el;

        form.addEventListener("submit", (ev)=> {
            ev.preventDefault();
            handleFormSubmit(form);
        });

        const inputs = form.querySelectorAll("input, textarea, select");

        for (let i = 0; i < inputs.length; ++i) {
            inputs.item(i).addEventListener("change", (ev)=> {
                const errors = validate(form, constraints, { fullMessages: false }) || {};
/*
                console.clear();
                console.log(errors);
*/
                showErrorsForInput(ev.currentTarget, errors[ev.currentTarget.name])
            });
        }

        function handleFormSubmit(form, input) {
            const errors = validate(form, constraints, { fullMessages: false });
            showErrors(form, errors || {});
            if (!errors) {
                showSuccess();
            } else {
                //window.runScroll('#applyForm');
            }
        };

        // Updates the inputs with the validation errors
        function showErrors(form, errors) {
            _.each(form.querySelectorAll("[required]"), (input)=> {
                showErrorsForInput(input, errors && errors[input.name]);
            });
        };

        // Shows the errors for a specific input
        function showErrorsForInput(input, errors) {
            const formGroup = closestParent(input.parentNode, "form-group"),
                  messages = formGroup.querySelector(".js-message");

            resetFormGroup(formGroup);

            if (errors) {
                formGroup.classList.add("has-error");
/*
                _.each(errors, function(error) {
                    if (messages) {
                        addError(messages, error);
                    }
                });
*/
            } else {
                formGroup.classList.add("has-success");

                if (formGroup.parentNode.classList.contains('form-children'))  {
                    document.getElementById(formGroup.parentNode.dataset.id).parentNode.parentNode.classList.remove('has-error');
                }
            }
        };

        // Recusively finds the closest parent that has the specified class
        function closestParent(child, className) {
            if (!child || child == document) {
                return null;
            }
            if (child.classList.contains(className)) {
                return child;
            } else {
                return closestParent(child.parentNode, className);
            }
        };

        function resetFormGroup(formGroup) {
            // Remove the success and error classes
            formGroup.classList.remove("has-error");
            formGroup.classList.remove("has-success");
            // and remove any old messages
            _.each(formGroup.querySelectorAll(".help-block.error"), function(el) {
                el.parentNode.removeChild(el);
            });
        }

        // Adds the specified error with the following markup
        // <p class="help-block error">[message]</p>
        function addError(messages, error) {
            const block = document.createElement("p");
            block.classList.add("help-block");
            block.classList.add("error");
            block.innerText = error;
            messages.appendChild(block);
        }

        function startAjax() {
            form.querySelector('button').classList.add('loading');
        }

        function finishAjax() {
            form.querySelector('button').classList.remove('loading');
        }

        function submitForm() {
            const postData = new FormData(form);
            let url = form.getAttribute('action');
            postData.append("email2", "");

            // test
            // Toastify({
            //     text: form.querySelector('.js-thanks').innerHTML,
            //     duration: 5000,
            //     className: "success",
            //     gravity: "bottom",
            //     position: "right",
            //     stopOnFocus: true,
            // }).showToast();
            // finishAjax();
            // for (const [key, value] of postData) {
            //     console.log(`${key}: ${value}\n`);
            // }

            const xmlHttp = new XMLHttpRequest();
            xmlHttp.onload = function() {
                if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
                    form.classList.add('is-filled');
                    window.runScroll('#applyForm');
/*
                    Toastify({
                        text: form.querySelector('.js-thanks').innerHTML,
                        duration: 5000,
                        className: "success",
                        gravity: "bottom",
                        position: "right",
                        stopOnFocus: true,
                    }).showToast();
*/
                    form.reset();
                } else if(xmlHttp.status == 422){

                    let isMsgError = false;
                    const fields = JSON.parse(xmlHttp.responseText).errors;
                    const message = JSON.parse(xmlHttp.responseText).message;

                    if(message && (message == 'ERROR01' || message == 'ERROR02')){
                        isMsgError = true;
                    }

                    if(!isMsgError) {
                        for(let index in fields){
                            const el = form.querySelector('[name="'+index+'"]');
                            if(el){
                                showErrorsForInput(el, fields[index][0]);
                            }
                        }

                        Toastify({
                            text: 'Popraw błędy w formularzu',
                            duration: 5000,
                            className: "error",
                            gravity: "bottom",
                            position: "right",
                            stopOnFocus: true,
                        }).showToast();
                    } else {
                        form.classList.add('is-message-error');
                    }

                } else {

                }
                finishAjax();
            };

            xmlHttp.open('post', url, true);
            xmlHttp.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xmlHttp.send(postData);
        }

        function showSuccess() {
            startAjax();
            submitForm();
        }
    };

    const applyform = ()=> {
        const constraints = {
            birthday: {
                presence: { message: "Pole nie może być puste" },
                format: {
                    pattern: "[a-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 ,.'-]+",
                    flags: "i",
                    message: "Może zawierać tylko znaki a-z i 0-9"
                }
            },
            /*
employer_id: {
                presence: { message: "Pole nie może być puste" },
                format: {
                    pattern: "[a-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 ,.'-]+",
                    flags: "i",
                    message: "Może zawierać tylko znaki a-z i 0-9"
                }
            },
*/
            username: {
                presence: { message: "Pole nie może być puste" },
                format: {
                    pattern: "[a-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 ,.'-]+",
                    flags: "i",
                    message: "Może zawierać tylko znaki a-z i 0-9"
                }
            }
        }

        init(document.querySelector('#applyForm'), constraints);
    };

    window.voteformValidate = ()=> {
        const constraints = {
/*
            birthday: {
                presence: { message: "Pole nie może być puste" },
                format: {
                    pattern: "[a-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 ,.'-]+",
                    flags: "i",
                    message: "Może zawierać tylko znaki a-z i 0-9"
                }
            },
*/
            employer_id: {
                presence: { message: "Pole nie może być puste" },
                format: {
                    pattern: "[a-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 ,.'-]+",
                    flags: "i",
                    message: "Może zawierać tylko znaki a-z i 0-9"
                }
            },
            username: {
                presence: { message: "Pole nie może być puste" },
                format: {
                    pattern: "[a-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 ,.'-]+",
                    flags: "i",
                    message: "Może zawierać tylko znaki a-z i 0-9"
                }
            }
        }

        init(document.querySelector('#voteForm'), constraints);
    };
    
    //window.voteformValidate();
    document.querySelector('#applyForm') ? applyform() : false;

}, false);
